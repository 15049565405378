<template>
  <div v-bind="listAttributes" class="p-list" @click="$emit('on-click')">
    <div class="p-list__inner">
      <div>
        <div class="p-list__title">
          <slot name="prepend" />
          <div>
            <span v-if="title" v-text="title" />
            <div v-if="description" class="p-list__desc" v-text="description" />
          </div>
        </div>
      </div>
      <slot name="append" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineEmits(['on-click'])

const props = defineProps({
  title: {
    type: [Number, String],
    default: null,
  },
  description: {
    type: [Number, String, Boolean],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  hover: {
    type: Boolean,
    default: false,
  },
})

const listAttributes = computed((): Record<string, any> => {
  return {
    ...(props.hover && { role: 'dialog' }),
    class: {
      'p-list': true,
      'p-list--disabled': props.disabled,
      'p-list--selected': props.selected,
      'p-list--hover': !props.disabled && props.hover,
    },
  }
})
</script>
